import { useNavigationType, useLocation } from 'react-router-dom';
import AppMobile from './AppMobile';
import { useEffect, useRef } from 'react';
import * as HttpContext from './httpContext';
import { isMobile } from 'react-device-detect';
import AppWeb from './AppWeb';

import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const jwtTokenRef = useRef();
  const userInfoRef = useRef();

  HttpContext.setJwtTokenRef(jwtTokenRef);
  HttpContext.setUserInfoRef(userInfoRef);

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: 'AIzaSyB1AKbpqYtlccr6lu0OgxLOwnPtoAKlD_I',
    authDomain: 'bluejay-408707.firebaseapp.com',
    projectId: 'bluejay-408707',
    storageBucket: 'bluejay-408707.appspot.com',
    messagingSenderId: '449248341356',
    appId: '1:449248341356:web:9970f11a72eb9ee25a1749',
    measurementId: 'G-B3KKX25JPG',
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const auth = initializeAuth(app, {
    persistence: [browserLocalPersistence, indexedDBLocalPersistence],
    popupRedirectResolver: browserPopupRedirectResolver,
  });

  localStorage.getItem('jwtToken')
    ? (jwtTokenRef.current = localStorage.getItem('jwtToken'))
    : (jwtTokenRef.current = undefined);

  function redditNormalizeEmail(email) {
    // 1. Convert to lowercase
    email = email.toLowerCase();

    // 2. Extract the local part (before @)
    const atIndex = email.indexOf('@');
    if (atIndex === -1) {
      return null; // Invalid email format
    }
    let localPart = email.substring(0, atIndex);
    const domainPart = email.substring(atIndex);

    // 3. Remove dots from the local part
    localPart = localPart.replace(/\./g, '');

    // 4. Remove text after "+" in the local part
    const plusIndex = localPart.indexOf('+');
    if (plusIndex !== -1) {
      localPart = localPart.substring(0, plusIndex);
    }

    // 5. Reconstruct the email and hash it
    const normalizedEmail = localPart + domainPart;

    // 6. Hash using SHA-256 (using crypto-js as an example)
    const hashedEmail = sha256(normalizedEmail).toString();

    return hashedEmail;
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      if (jwtTokenRef.current === undefined) {
        jwtTokenRef.current = localStorage.getItem('jwtToken');
        await HttpContext.updateAccountInfo(jwtTokenRef, userInfoRef);
        if (userInfoRef.current === undefined) {
          localStorage.removeItem('jwtToken');
        } else {
          if (window.rdt) {
            const rdt = window.rdt;
            rdt('init', 'a2_g8o2uvul00sx', {
              email: redditNormalizeEmail(userInfoRef.current.email),
            });
          }
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  // Reddit Pixel
  useEffect(() => {
    if (document.referrer.includes('reddit.com') && !window.rdt) {
      const rdt = function () {
        rdt.sendEvent
          ? rdt.sendEvent.apply(rdt, arguments)
          : rdt.callQueue.push(arguments);
      };
      rdt.callQueue = [];
      window.rdt = rdt;

      const script = document.createElement('script');
      script.src = 'https://www.redditstatic.com/ads/pixel.js';
      script.async = true;

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);

      rdt('init', 'a2_g8o2uvul00sx');
      rdt('track', 'PageVisit');
    }
  }, []);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    switch (pathname) {
      case '/':
        title = 'Interpret AI Translator / Transcriber';
        metaDescription =
          'A powerful tool for translating and transcribing audio and video content using AI technology.';
        break;
      case '/file-translate':
        title = 'Interpret AI File Translator';
        metaDescription =
          'Easily translate documents and files with our AI-powered file translation service.';
        break;
      case '/audio-translate':
        title = 'Interpret AI Audio/Video Transcriber';
        metaDescription =
          'Transcribe audio and video files accurately with our advanced AI transcription service.';
        break;
      case '/transcribe':
        title = 'Interpret AI Transcriber';
        metaDescription =
          'Convert spoken language into written text effortlessly with our AI transcription tool.';
        break;
      case '/translate':
        title = 'Interpret AI Transcriber';
        metaDescription =
          'Utilize our AI to translate text and speech in real-time for seamless communication.';
        break;
      case '/zoom_instructions':
        title = 'Interpret AI Zoom Integration Instructions';
        metaDescription =
          'Learn how to integrate and use Interpret AI with Zoom for enhanced meeting experiences.';
        break;
      case '/docs':
        title = 'Interpret AI Docs';
        metaDescription =
          'Access comprehensive documentation and guides for using Interpret AI effectively.';
        break;
      case '/profile':
        title = 'Interpret AI Profile';
        metaDescription =
          'Manage your user profile and settings for a personalized experience with Interpret AI.';
        break;
      case '/profile_payment_info':
        title = 'Interpret AI Profile Payment Info';
        metaDescription =
          'View and manage your subscription details for Interpret AI.';
        break;
      case '/login':
        title = 'Interpret AI Login';
        metaDescription =
          'Log in to your Interpret AI account to access all features and services.';
        break;
      case '/signup':
        title = 'Interpret AI Sign Up';
        metaDescription =
          'Create your Interpret AI account to unlock all features and services.';
        break;
      case '/plan':
        title = 'Interpret AI Subscription Plans';
        metaDescription =
          'Explore our subscription plans and choose the best option for your needs.';
        break;
      case '/admin':
        title = 'Interpret AI Admin Page';
        metaDescription =
          'Interpret AI Admin Portal.';
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]',
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return isMobile ? <AppMobile /> : <AppWeb />;
}
export default App;
