import React, { useState, useEffect } from 'react';
import { jwtTokenRef, serverURL } from '../../httpContext';

export const AdminPage = () => {
  const [adminStatus, setAdminStatus] = useState({
    loading: true,
    isAdmin: false,
    error: null,
  });

  const [formData, setFormData] = useState({
    email: '',
    credits: '',
  });

  const [updateStatus, setUpdateStatus] = useState({
    loading: false,
    success: null,
    error: null,
  });

  // Check admin status on component mount
  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await fetch(`${serverURL}/admin_status`, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + jwtTokenRef.current,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch admin status');
        }

        setAdminStatus({ loading: false, isAdmin: true, error: null });
      } catch (error) {
        setAdminStatus({ loading: false, isAdmin: false, error: error.message });
      }
    };

    checkAdminStatus();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateStatus({ loading: true, success: null, error: null });

    try {
      const response = await fetch(`${serverURL}/admin_load_credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtTokenRef.current,
        },
        body: JSON.stringify({
          email: formData.email,
          credits: parseInt(formData.credits, 10),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to update user credits');
      }

      setUpdateStatus({
        loading: false,
        success: 'User credits updated successfully!',
        error: null,
      });
      setFormData({ email: '', credits: '' });
    } catch (error) {
      setUpdateStatus({
        loading: false,
        success: null,
        error: error.message,
      });
    }
  };

  if (adminStatus.loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-r from-purple-500 to-indigo-600">
        <div className="text-white text-4xl font-bold animate-pulse">
          Loading...
        </div>
      </div>
    );
  }

  if (!adminStatus.isAdmin) {
    return (
      <div className="w-full h-full flex items-center justify-center h-screen bg-gradient-to-r from-red-500 to-pink-600">
        <div className="text-white text-4xl font-bold">
          Access Denied. You are not an admin.
        </div>
      </div>
    );
  }

  return (
    <section className="w-full h-full py-28 max-w-[1512px] mx-auto flex flex-col gap-14 text-left text-2.5xl text-not-black-black font-montserrat">
      {/* Header Section */}
      <div className="self-stretch flex flex-col gap-5">
        <div className="flex flex-row">
          <h1 className="mx-auto font-semibold text-center text-4xl">
            Admin Dashboard
          </h1>
        </div>
        <div className="self-stretch font-medium text-center">
          <p className="m-0 mx-2 sm:mx-0">
            Update user credits by entering their email and the desired credit amount.
          </p>
        </div>
      </div>

      {/* Form Container */}
      <div className="self-stretch flex flex-col items-center px-5 max-w-full text-lg">
        <form
          className="w-full max-w-[29.188rem] flex flex-col gap-7"
          onSubmit={handleSubmit}
        >
          <div className="self-stretch flex flex-col gap-2">
            <label htmlFor="email" className="font-medium">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="pl-2 border-none bg-transparent self-stretch h-[2.375rem] rounded-mini bg-gradient-to-r from-blue-100 to-indigo-100 text-lg"
            />
          </div>
          <div className="self-stretch flex flex-col gap-2">
            <label htmlFor="credits" className="font-medium">
              Credits
            </label>
            <input
              id="credits"
              name="credits"
              type="number"
              value={formData.credits}
              onChange={handleChange}
              required
              className="pl-2 border-none bg-transparent self-stretch h-[2.375rem] rounded-mini bg-gradient-to-r from-blue-100 to-indigo-100 text-lg"
            />
          </div>
          <button
            type="submit"
            className="w-fit text-not-black self-center p-2 px-3 rounded-[10px] border-not-black border border-solid text-lg font-medium disabled:opacity-50 hover:bg-gradient-to-r from-blue-500 to-indigo-500 hover:text-white transition-all"
            disabled={updateStatus.loading}
          >
            {updateStatus.loading ? 'Updating...' : 'Update Credits'}
          </button>
          {updateStatus.error && (
            <div className="text-red-500 text-center mt-2">
              {updateStatus.error}
            </div>
          )}
          {updateStatus.success && (
            <div className="text-green-500 text-center mt-2">
              {updateStatus.success}
            </div>
          )}
        </form>
      </div>
    </section>
  );
};