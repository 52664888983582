import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, serverURL } from '../../httpContext';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { RenameFilePopup } from '../../components/RenameFilePopup';
import { RenameFolderPopup } from '../../components/RenameFolderPopup';
import { FolderPopup } from '../../components/FolderPopup';
import { MoreOutlined, PlusOutlined, FolderOutlined } from '@ant-design/icons';
import { FolderFiles } from '../../components/FolderFiles';

const RecentFiles = ({ recentFiles, loadMoreFiles, navigate, folders }) => {
  const [currentFiles, setCurrentFiles] = useState(recentFiles);
  const currentFilesRef = useRef(currentFiles);
  const scrollingContainerRef = useRef(null);
  const scrollEndRef = useRef(null);
  const observer = useRef(null);
  const [openRenameFilePopup, setOpenRenameFilePopup] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileId, setFileId] = useState('');
  const [fileIndex, setFileIndex] = useState(-1);
  const [openMoveFilePopup, setOpenMoveFilePopup] = useState(false);

  const lastScrollTopRef = useRef(0);

  const handleFileRemove = (fileToRemove) => {
    console.log('calling delete transcript ' + JSON.stringify(fileToRemove));
    fetch(serverURL + '/delete_transcript?transcript_id=' + fileToRemove.id, {
      method: 'POST',
      body: JSON.stringify({ fileName: fileToRemove.name }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtTokenRef.current}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log('File deleted successfully');
          const newFiles = currentFiles.filter(
            (file) => file.id !== fileToRemove.id,
          );
          setCurrentFiles(newFiles);
        } else {
          console.error('Failed to delete file');
        }
      })
      .catch((error) => {
        console.error('Error deleting file:', error);
      });
  };

  const handleFileRename = async (name) => {
    console.log('rename file ' + fileId + ' to ' + name);
    setOpenRenameFilePopup(false);
    const response = await fetch(
      serverURL +
        '/rename_transcript?transcript_id=' +
        fileId +
        '&new_name=' +
        name,
      {
        method: 'POST',
        body: JSON.stringify({ fileName: name }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      },
    );

    if (response.ok) {
      console.log('File renamed successfully');
      const newFiles = currentFiles.map((file) => {
        if (file.id === fileId) {
          file.name = name;
        }
        return file;
      });
      setCurrentFiles(newFiles);
    } else {
      console.error('Failed to rename file');
    }
  };

  const handleFileMove = async (transcriptId, folderId) => {
    const res = await axios.post(
      `${serverURL}/add_file_to_folder?folder_id=${folderId}&file_id=${transcriptId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json', // Ensure the response is a blob
      },
    );

    if (res.status === 200) {
      console.log('File moved successfully');
    }
  };

  useEffect(() => {
    currentFilesRef.current = currentFiles;
  }, [currentFiles]);

  useEffect(() => {
    setCurrentFiles(recentFiles);
  }, [recentFiles]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        console.log('intersecting', currentFilesRef.current.length);
      }
      if (entries[0].isIntersecting && currentFilesRef.current.length > 0) {
        lastScrollTopRef.current = scrollingContainerRef.current.scrollTop;
        console.log('lastScrollTopRef.current', lastScrollTopRef.current);
        loadMoreFiles();
      }
    }, options);

    const target = scrollEndRef.current;
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current && target) {
        observer.current.unobserve(target);
      }
    };
  }, []);

  return (
    <div
      ref={scrollingContainerRef}
      className='h-[400px] overflow-y-auto flex flex-col text-sm text-updated-color-grey3'
    >
      {openRenameFilePopup && (
        <RenameFilePopup
          onClose={() => setOpenRenameFilePopup(false)}
          onSubmit={handleFileRename}
          name={fileName}
        />
      )}
      <div className='flex flex-col w-full py-[5px] px-0 relative gap-[10px]'>
        {currentFiles.map((file, indx) => (
          <div
            key={file.id}
            className='relative w-full cursor-pointer'
            onClick={() => {
              navigate(`/file_transcript?id=${file.id}`, {
                state: {
                  file: file,
                },
              });
            }}
          >
            <div className='rounded-3xs bg-app-close-to-white-grey-bkg grid grid-cols-4 items-center py-2.5 pl-2.5 pr-2 text-base text-updated-color-new-black hover:bg-updated-color-grey1 transition-all duration-300'>
              <div className='relative leading-[22px] font-medium pr-10'>
                {file.name}
              </div>
              <div className='leading-[22px] font-medium text-updated-color-grey3'>
                {file.transcript_type === 'gpu' || file.transcript_type === 'gemini'
                  ? 'Audio/Video Transcript'
                  : file.transcript_type === 'image'
                    ? 'Image Transcription'
                    : ''}
              </div>
              <div className=' leading-[22px] font-medium whitespace-nowrap text-updated-color-grey3'>
                {format(parseISO(file.created_at), 'MMM dd, yyyy')}
              </div>
              <div className='flex justify-end gap-[5px] pr-10 relative'>
                <div
                  className='rounded-4xl overflow-hidden flex p-[5px] hover:bg-sky-200'
                  onClick={(e) => {
                    e.stopPropagation();
                    setFileName(file.name);
                    setFileId(file.id);
                    setOpenRenameFilePopup(true);
                  }}
                >
                  <img className='w-5 h-5' alt='' src='/icon-frame1.svg' />
                </div>
                {/* <div className="rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]">
									<img className="w-4 h-4" alt="" src="/icon-frame2.svg" />
								</div>
								<div className="rounded-4xl overflow-hidden flex flex-row items-start justify-start p-[5px]">
									<img className="w-4 h-4" alt="" src="/icon-frame3.svg" />
								</div> */}
                <div
                  className='rounded-4xl overflow-hidden flex p-[5px] hover:bg-sky-200'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFileRemove(file);
                  }}
                >
                  <img className='w-5 h-5' alt='' src='/icon-frame4.svg' />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenMoveFilePopup(!openMoveFilePopup);
                    setFileIndex(indx);
                  }}
                  className='rounded-4xl overflow-hidden flex p-[5px] hover:bg-sky-200'
                >
                  <img className='w-5 h-5' alt='' src='/icon-frame5.svg' />
                </div>
                {openMoveFilePopup && fileIndex === indx && (
                  <div
                    onMouseLeave={() => setOpenMoveFilePopup(false)}
                    className='absolute top-2 right-10 w-32 max-h-20 bg-white shadow overflow-y-scroll z-10 rounded-md py-2'
                  >
                    <div className='flex flex-col gap-2'>
                      <div className='px-2 font-semibold'>Move to:</div>
                      {folders.map((folder) => {
                        if (folder.id === file.folder_id) return null;
                        return (
                          <div className='flex items-center gap-2 p-2 hover:bg-sky-200'>
                            <FolderOutlined />
                            <div
                              className=''
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFileMove(file.id, folder.id, file.folder);
                                setOpenMoveFilePopup(false);
                              }}
                            >
                              {folder.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        <div ref={scrollEndRef}></div>
      </div>
    </div>
  );
};

const SearchBar = ({
  fetchFiles,
  setRecentFiles,
  currentPageRef,
  transcriptTypeRef,
  queryRef,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        handleSearch(newQuery);
      }, 2000),
    );
  };

  const handleSearch = async (query) => {
    currentPageRef.current = 1;
    queryRef.current = query;
    const result = await fetchFiles(
      1,
      transcriptTypeRef.current,
      false,
      queryRef.current,
    );
    setRecentFiles(result.files);

    console.log('searching for', queryRef.current);
  };

  return (
    <div className='w-96 rounded-full [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-row items-center justify-start py-2.5 pl-5 text-lg text-updated-color-grey2'>
      <div className='flex flex-row items-center justify-start gap-[15px] w-full'>
        <img
          className='w-[22.9px] relative h-[22.9px] overflow-hidden shrink-0'
          alt=''
          src='/general--search1.svg'
        />
        <input
          className='w-full relative font-medium bg-transparent border-none outline-none font-montserrat text-lg'
          placeholder='Search in My Documents'
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );
};

const Docs = () => {
  const navigate = useNavigate();
  const [recentFiles, setRecentFiles] = useState([]);
  const recentFilesRef = useRef(recentFiles);
  const transcriptTypeRef = useRef(undefined);
  const [imageTranslateFilesTotal, setImageTranslateFilesTotal] = useState(0);
  const [gpuTranslateFilesTotal, setGpuTranslateFilesTotal] = useState(0);
  const [openRenameFolderPopup, setOpenRenameFolderPopup] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [folders, setFolders] = useState([]);
  const [folderId, setFolderId] = useState(null);
  const [openedFolder, setOpenedFolder] = useState(false);
  const [viewAllFolders, setViewAllFolders] = useState(false);
  const [viewAllFiles, setViewAllFiles] = useState(false);
  const [editFolderPopup, setEditFolderPopup] = useState(false);
  const [folderIndex, setFolderIndex] = useState(-1);
  const [numFolders, setNumFolders] = useState(4);
  const [title, setTitle] = useState('Recently Saved Files');
  const currentPageRef = useRef(1);
  const queryRef = useRef('');
  const ascRef = useRef(false);

  const refreshFolders = async () => {
    try {
      const response = await axios.get(`${serverURL}/list_folders`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      let retrievedFolders = response.data.folders.map((folder) => ({
        name: folder.name,
        id: folder.id,
        path: folder.path,
      }));

      if (numFolders) setFolders(() => retrievedFolders.slice(0, numFolders));
      else setFolders(() => retrievedFolders);
      console.log('Folders:', retrievedFolders);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  };

  const handleFolderRename = async (newName) => {
    try {
      await fetch(
        `${serverURL}/rename_folder?folder_id=${folderId}&new_name=${newName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      console.log('Folder renamed successfully');
      refreshFolders();
    } catch (error) {
      console.error('Error renaming folder:', error);
    }
    setOpenRenameFolderPopup(false);
  };

  const handleFolderRemove = async (folder) => {
    try {
      await fetch(`${serverURL}/delete_folder?folder_id=${folder.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      console.log('Folder deleted successfully');
      refreshFolders();
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  const handleFolderNameSubmit = async (folderName) => {
    try {
      await fetch(serverURL + '/create_folder?folder_name=' + folderName, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      console.log('Folder created successfully: ' + folderName);
    } catch (error) {
      console.error('Error creating folder: ' + error);
    }
    refreshFolders();
    setCreateFolderOpen(false);
  };

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    try {
      const response = await axios.post(
        `${serverURL}/add_bot_to_zoom`,
        { meeting_url: meetingUrl },
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.status === 200) {
        console.log('Bot added to meeting');
        const { id, password } = response.data;
        navigate(`/broadcast/${id}?password=${password}`);
      } else {
        console.error('Failed to add bot to meeting');
      }
    } catch (error) {
      console.error('Error adding bot to meeting:', error);
    }
  };

  const fetchFiles = async (page, transcript_type, asc, query) => {
    try {
      console.log('fetching files');
      console.log(folderId);
      let response;
      try {
        response = await axios.get(`${serverURL}/list_transcripts`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
          params: {
            folder_id: folderId, // Update as needed
            page_size: 10, // Update as needed
            page_number: page, // Use the page parameter
            get_raw_transcript: false, // Update as needed,
            transcript_type: transcript_type,
            asc: asc,
            text_search: query,
          },
        });
      } catch (error) {
        console.error('Error fetching transcripts:', error);
        return;
      }
      let data = response.data;
      const total = data.total;
      data = data.transcripts;
      console.log('data:', data);
      const retrievedFiles = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].transcript === null && data[i].transcript_type !== 'file') {
          continue;
        }
        retrievedFiles.push(data[i]);
      }
      return {
        files: retrievedFiles,
        total: total,
      };
    } catch (error) {
      console.error('Error fetching files:', error);
      return [];
    }
  };

  const loadMoreFiles = () => {
    const fetchData = async () => {
      const nextPage = currentPageRef.current + 1;
      currentPageRef.current = nextPage;
      const result = await fetchFiles(
        nextPage,
        transcriptTypeRef.current,
        ascRef.current,
        queryRef.current,
      );
      if (result.files.length === 0) {
        return;
      }
      setRecentFiles([...recentFilesRef.current, ...result.files]);
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      let result = fetchFiles(1); // Load the first page on component mount
      let fetchedImageTranslateTotal = fetchFiles(1, 'image');
      var fetchedGpuTranslateTotal = await fetchFiles(1, 'gpu');
      var fetchedGpuTranslateTotal = await fetchFiles(1, 'gemini');
      fetchedImageTranslateTotal = await fetchedImageTranslateTotal;
      result = await result;
      setRecentFiles(result.files);
      setImageTranslateFilesTotal(fetchedImageTranslateTotal.total);
      setGpuTranslateFilesTotal(fetchedGpuTranslateTotal.total);
    };

    fetchData();
    refreshFolders();
  }, [folderId]);

  useEffect(() => {
    refreshFolders();
  }, [viewAllFolders]);

  return (
    <div className='max-w-[1512px] w-full mx-auto relative text-left text-xs text-updated-color-new-black font-montserrat'>
      <div className='flex flex-col p-10 px-20 gap-20 min-h-screen'>
        <div className='flex items-center justify-between w-full'>
          <div className='flex items-center gap-4'>
            {(openedFolder || viewAllFiles) && (
              <img
                className='w-fit cursor-pointer'
                alt=''
                src='/group-1707478204.svg'
                onClick={() => {
                  navigate('/docs');
                  setOpenedFolder(false);
                  setFolderName('');
                  setFolderId(null);
                  setViewAllFiles(false);
                  setTitle('Recently Saved Files');
                }}
              />
            )}
            <SearchBar
              fetchFiles={fetchFiles}
              setRecentFiles={setRecentFiles}
              currentPageRef={currentPageRef}
              transcriptTypeRef={transcriptTypeRef}
              queryRef={queryRef}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col justify-center relative w-full gap-1'>
              {/* <img
                src='/cam.svg'
                alt=''
                className='w-6 h-6 absolute top-2 left-2'
              /> */}
              <img
                src='/youtube.png'
                alt=''
                className='w-6 h-6 absolute top-2 left-2'
              />
              <input
                placeholder='Paste Youtube URL'
                className='text-base pl-8 py-2.5 border-solid rounded-full border border-updated-color-grey1 focus:outline-none bg-transparent shadow'
                onKeyDown={async (e) => {
                  if (e.key === 'Enter') {
                    try {
                      const response = await axios.post(
                        `${serverURL}/transcribe_youtube?youtube_url=${encodeURIComponent(e.target.value)}`,
                        {},
                        {
                          headers: {
                            Authorization: `Bearer ${jwtTokenRef.current}`,
                          },
                        },
                      );
                      const { transcript_id } = response.data;
                      navigate(`/file_transcript?id=${transcript_id}`);
                    } catch (error) {
                      console.error('Error transcribing YouTube video:', error);
                    }
                  }
                }}
              />
            </div>
            <div className='flex flex-col justify-center relative w-80 gap-1'>
              <img
                src='/cam.svg'
                alt=''
                className='w-6 h-6 absolute top-2 left-2'
              />
              <input
                placeholder='Paste Meeting URL'
                className='text-base pl-8 py-2.5 border-solid rounded-full border border-updated-color-grey1 focus:outline-none bg-transparent shadow'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addBotToMeeting(e.target.value);
                  }
                }}
              />
              <div className='flex items-center text-[#c5c5c5] font-bold self-end gap-1 text-base'>
                Supports
                <img
                  src='/platforms.svg'
                  alt='Meeting platform logos'
                  className='w-20'
                />
              </div>
            </div>
          </div>
        </div>
        {openRenameFolderPopup && (
          <RenameFolderPopup
            onClose={() => setOpenRenameFolderPopup(false)}
            onSubmit={handleFolderRename}
            name={folderName}
          />
        )}
        {!viewAllFiles && !openedFolder && (
          <div className='flex flex-col gap-4'>
            <div className='flex items-center justify-between'>
              <div className='font-semibold text-xl'>Folders</div>
              <div
                onClick={() => {
                  if (viewAllFolders) setNumFolders(4);
                  else setNumFolders(0);
                  setViewAllFolders(!viewAllFolders);
                }}
                className='text-base cursor-pointer hover:text-updated-color-blue'
              >
                {viewAllFolders ? 'View Less' : 'View All'}
              </div>
            </div>
            <div className='grid grid-cols-5 gap-4'>
              {createFolderOpen && (
                <FolderPopup
                  onClose={() => setCreateFolderOpen(false)}
                  onSubmit={handleFolderNameSubmit}
                />
              )}
              {!viewAllFolders && !viewAllFiles && (
                <div
                  onClick={() => setCreateFolderOpen(true)}
                  className='cursor-pointer rounded-3xs border-solid border-[.5px] border-updated-color-grey1 flex flex-col gap-1 items-center justify-center w-11/12 hover:bg-updated-color-grey1 transition-all duration-300'
                >
                  <div className='bg-whitesmoke w-fit p-2 rounded flex items-center justify-center'>
                    <PlusOutlined className='text-lg' />
                  </div>
                  <div className='text-sm font-medium'>Create a Folder</div>
                </div>
              )}
              {folders.map((folder, indx) => (
                <div
                  onClick={() => {
                    setOpenedFolder(true);
                    setFolderName(folder.name);
                    setFolderId(folder.id);
                    setTitle(folder.name);
                  }}
                  className={`cursor-pointer px-4 shadow-[0px_1px_3.3px_rgba(140,_140,_140,_0.09)] rounded-3xs bg-app-close-to-white-grey-bkg flex py-5 relative gap-4 border-[0.5px] border-solid border-updated-color-grey1 w-10/12 ${!editFolderPopup && 'hover:bg-updated-color-grey1'} transition-all duration-300 overflow-visible`}
                >
                  <MoreOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditFolderPopup(true);
                      setFolderIndex(indx);
                    }}
                    className='absolute top-3 right-1 text-2xl p-1'
                  />
                  {editFolderPopup && folderIndex === indx && (
                    <div
                      className='absolute top-3 -right-40 flex flex-col gap-2 bg-white py-2 shadow rounded-md w-48 z-10'
                      onMouseLeave={() => setEditFolderPopup(false)}
                    >
                      <div
                        className='text-base hover:bg-sky-200 p-1'
                        onClick={(e) => {
                          e.stopPropagation();
                          setFolderName(folder.name);
                          setOpenRenameFolderPopup(true);
                          setFolderId(folder.id);
                          setEditFolderPopup(false);
                        }}
                      >
                        Rename
                      </div>
                      <div
                        className='text-base hover:bg-sky-200 p-1'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFolderRemove(folder);
                          setEditFolderPopup(false);
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                  <div className='rounded-3xs bg-updated-color-purple flex flex-col items-start justify-start py-[13px] px-[11px] gap-[10px] z-[1]'>
                    <img
                      className='w-[25px] relative h-[19.4px]'
                      alt=''
                      src='/vector-70.svg'
                    />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <div className='font-medium text-base'>{folder.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className='flex flex-col gap-4'>
          <div className='flex items-center justify-between'>
            <div className='font-semibold text-xl'>{title}</div>
            {!openedFolder && (
              <div
                onClick={() => {
                  if (viewAllFiles) setTitle('Recently Saved Files');
                  else setTitle('All Files');
                  setViewAllFiles(!viewAllFiles);
                  setFolderId(null);
                }}
                className='text-base cursor-pointer hover:text-updated-color-blue'
              >
                {viewAllFiles ? 'View Less' : 'View All'}
              </div>
            )}
          </div>

          <div className='flex gap-[20px] text-sm'>
            <div
              className='rounded-33xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-row items-center justify-center py-[5px] px-3 cursor-pointer hover:bg-gradient-to-r hover:from-[#007ef3] hover:to-[#7977d1] hover:text-white transition-all duration-300'
              onClick={() => {
                const fetchData = async () => {
                  currentPageRef.current = 1;
                  ascRef.current = false;
                  const result = await fetchFiles(
                    currentPageRef.current,
                    transcriptTypeRef.current,
                    false,
                    queryRef.current,
                  ); // Load the first page on component mount
                  setRecentFiles(result.files);
                };
                fetchData();
              }}
            >
              <div className='relative leading-[22px] font-medium'>Latest</div>
            </div>
            <div
              className='rounded-33xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-row items-center justify-center py-[5px] px-3 cursor-pointer hover:bg-gradient-to-r hover:from-[#007ef3] hover:to-[#7977d1] hover:text-white transition-all duration-300'
              onClick={() => {
                const fetchData = async () => {
                  currentPageRef.current = 1;
                  ascRef.current = true;
                  const result = await fetchFiles(
                    currentPageRef.current,
                    transcriptTypeRef.current,
                    true,
                    queryRef.current,
                  ); // Load the first page on component mount
                  setRecentFiles(result.files);
                };
                fetchData();
              }}
            >
              <div className='relative leading-[22px] font-medium'>
                Earliest
              </div>
            </div>
          </div>
          <div className='grid grid-cols-4 w-full text-sm text-updated-color-grey3'>
            <div className=' leading-[22px] font-medium'>Name</div>
            <div className=' leading-[22px] font-medium'>Type</div>
            <div className=' leading-[22px] font-medium'>Created</div>
          </div>
          {!openedFolder && (
            <RecentFiles
              recentFiles={recentFiles}
              loadMoreFiles={loadMoreFiles}
              navigate={navigate}
              folders={folders}
            />
          )}
          {openedFolder && (
            <FolderFiles
              folders={folders}
              recentFiles={recentFiles.filter(
                (file) => file.folder_id === folderId,
              )}
              loadMoreFiles={loadMoreFiles}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Docs;
