import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { socket } from '../../socketContext';
import { top100WorldLanguages } from '../../languages';
import { jwtTokenRef, translateTexts, userInfoRef } from '../../httpContext';
import RoomLinkAndQRCode from '../../components/mobile/RoomLinkAndQRCode';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { format } from 'date-fns';
import { serverURL } from '../../httpContext';
import axios from 'axios';
import ChatbotWidget from './ChatbotWidget';
import { Switch } from '../../components/Switch';
import { playNextAudio, computeBlob } from '../../utils';

const formatDate = (date) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

const BroadcastRoom = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const [fontSize, setFontSize] = useState('medium');
  const [fontSizeDropdownVisible, setFontSizeDropdownVisible] = useState(false);
  const [showLinkQRCode, setShowLinkQRCode] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesMerged, setMessagesMerged] = useState([]);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState('');
  const [inputLanguageDisplay, setInputLanguageDisplay] = useState('Translate');
  const [showBackTooltip, setShowBackTooltip] = useState(false);
  const [showShareTooltip, setShowShareTooltip] = useState(false);

  const linkAndQRCodeRef = useRef(null);
  const messagesRef = useRef(messages);
  const messagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const usingInputLanguagePopupRef = useRef(false);
  const inputLanguage = useRef(null);
  const inputLanguageRef = useRef(null);
  const inputLanguageDropdownRef = useRef(null);
  var startOfMessagesToKeepRef = useRef(null);
  const [lottieRef, setLottieRef] = useState(null);
  const roomIdRef = useRef(null);
  const passwordRef = useRef(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const isChatbotOpenRef = useRef(isChatbotOpen);
  const chatbotContextRef = useRef(null);
  const playingAudioRef = useRef(false);
  const liveAudioEnabledRef = useRef(false);
  const [liveAudioEnabled, setLiveAudioEnabled] = useState(false);
  var blankSrc =
    'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  const audioContextRef = useRef(new Audio(blankSrc));
  audioContextRef.current.autoplay = true;
  const audioQueueRef = useRef([]);
  const startProcessingIndexRef = useRef(messagesRef.current.length);

  const date = new Date();
  const formattedDate = formatDate(date);

  useEffect(() => {
    isChatbotOpenRef.current = isChatbotOpen;
  }, [isChatbotOpen]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  // Add fullscreen toggle function
  const toggleFullScreen = () => {
    const container = messagesContainerRef.current.parentElement;
    
    if (!document.fullscreenElement) {
      container.requestFullscreen().then(() => {
        setIsFullScreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullScreen(false);
      });
    }
  };

  const toggleLiveAudio = () => {
    if (!liveAudioEnabled) {
      // // Enable live audio
      // audioContextRef.current = new (window.AudioContext ||
      // 	window.webkitAudioContext)();

      // // Resume the audio context if it's suspended
      // if (audioContextRef.current.state === 'suspended') {
      // 	audioContextRef.current.resume().then(() => {
      // 		console.log('AudioContext resumed');
      // 	});
      // }
      audioContextRef.current.play();
      // new Audio(blankSrc)
      // audioContextRef.current.autoplay = true;

      setLiveAudioEnabled(true);

      // Set the starting index to the current messages length
      var lastFinalMessage = 0;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i]['is_final']) {
          lastFinalMessage = i + 1;
          break;
        }
      }
      startProcessingIndexRef.current = lastFinalMessage;
    } else {
      // Disable live audio
      setLiveAudioEnabled(false);
      // Clear the audio queue and stop playback
      audioQueueRef.current = [];
      playingAudioRef.current = false;
      audioContextRef.current.pause();
    }
  };

  useEffect(() => {
    liveAudioEnabledRef.current = liveAudioEnabled;
  }, [liveAudioEnabled]);

  var tasks = [];

  useEffect(() => {
    console.log('Live audio enabled:', liveAudioEnabled);
    console.log('Messages length:', messages.length);
    console.log('Start processing index:', startProcessingIndexRef.current);

    if (!liveAudioEnabledRef.current) {
      return;
    }

    if (tasks.length > 0) {
      return;
    }

    var textToAdd = '';
    for (let i = startProcessingIndexRef.current; i < messages.length; i++) {
      const message = messages[i];
      console.log('Processing message:', message);
      if (message['is_final']) {
        let text = message['transcript'];
        if (
          inputLanguage.current !== 'Translate' &&
          message['corrected_transcripts'][inputLanguage.current] !== undefined
        ) {
          text = message['corrected_transcripts'][inputLanguage.current];
        }
        textToAdd = textToAdd + ' ' + text;
        console.log('Text to add:', textToAdd);
        // var blob = computeBlob(text)
        // tasks.push(blob)
      }
    }
    if (textToAdd != '') {
      var blob = computeBlob(textToAdd, roomIdRef);
      tasks.push(blob);
    }

    var lastFinalMessage = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i]['is_final']) {
        lastFinalMessage = i + 1;
        break;
      }
    }
    startProcessingIndexRef.current = lastFinalMessage;

    // wait for all the blobs to be computed
    Promise.all(tasks).then((blobs) => {
      // const mergedBlob = new Blob(blobs); // Merge the blobs into one
      for (let i = 0; i < blobs.length; i++) {
        audioQueueRef.current.push(blobs[i]);
      }
      tasks = [];
      playNextAudio(
        playingAudioRef,
        audioQueueRef,
        audioContextRef,
        liveAudioEnabledRef,
      );
    });
  }, [messages, liveAudioEnabled]);

  const updateMessagesMerged = (messagesUpdated) => {
    // copy the messagesUpdated array to avoid reference issues
    var messagesUpdatedCopy = JSON.parse(JSON.stringify(messagesUpdated));
    var messagesMergedUpdated = [];
    var startOfMessagesToKeep = -1;
    for (var i = 0; i < messagesUpdated.length; i++) {
      if (messagesUpdated[i]['transcript'] != '') {
        const currentTimestamp = messagesUpdated[i]['timestamp'];
        const lastMergedMessage =
          messagesMergedUpdated[messagesMergedUpdated.length - 1];

        if (
          i > 0 &&
          !messagesUpdated[i]['did_speaker_change'] &&
          !messagesUpdated[i]['is_ai_answer'] &&
          !messagesUpdated[i - 1]['is_ai_answer'] &&
          messagesMergedUpdated.length > 0 &&
          messagesUpdated[i]['transcript_id'] !=
            startOfMessagesToKeepRef.current &&
          lastMergedMessage['messages_merged'] < 4 &&
          currentTimestamp - lastMergedMessage['timestamp'] <= 60 // Check if the time gap is less than or equal to 7 seconds
        ) {
          lastMergedMessage['transcript'] =
            lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

          lastMergedMessage['messages_merged']++;

          for (var key in messagesUpdated[i]['corrected_transcripts']) {
            if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
              lastMergedMessage['corrected_transcripts'][key] = '';
            }
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              messagesUpdated[i]['corrected_transcripts'][key];
          }
        } else {
          if (messagesUpdatedCopy > 10 && startOfMessagesToKeep == -1) {
            startOfMessagesToKeep = i;
            startOfMessagesToKeepRef.current =
              messagesUpdated[i]['transcript_id'];
          }
          messagesUpdated[i]['messages_merged'] = 1;
          const timestamp = new Date(currentTimestamp * 1000);
          messagesUpdated[i]['timestamp_display'] =
            timestamp.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
          messagesMergedUpdated.push(messagesUpdated[i]);
        }
      }
    }

    if (messagesMergedUpdated.length > 0) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      lastMessageMerged['unconfirmed_transcript'] = '';
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
    }

    if (
      messagesUpdatedCopy.length > 0 &&
      !messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['is_final']
    ) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      var lengthOfLastMessage =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript']
          .length;
      lastMessageMerged['transcript'] = messagesMergedUpdated[
        messagesMergedUpdated.length - 1
      ]['transcript'].slice(0, -lengthOfLastMessage);
      lastMessageMerged['unconfirmed_transcript'] =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript'];
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
      for (var key in lastMessageMerged['corrected_transcripts']) {
        lengthOfLastMessage =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key].length;
        lastMessageMerged['corrected_transcripts'][key] = lastMessageMerged[
          'corrected_transcripts'
        ][key].slice(0, -lengthOfLastMessage);
        lastMessageMerged['unconfirmed_corrected_transcripts'][key] =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key];
      }
    }

    // ensure only last 30 messages are stored
    if (messagesMergedUpdated.length > 15) {
      messagesMergedUpdated = messagesMergedUpdated.slice(
        messagesMergedUpdated.length - 15,
      );
      console.log('startOfMessagesToKeep: ' + startOfMessagesToKeep);

      setMessages(messagesUpdatedCopy.slice(startOfMessagesToKeep));
      messagesRef.current = messagesUpdatedCopy.slice(startOfMessagesToKeep);
    } else {
      setMessages(messagesUpdatedCopy);
      messagesRef.current = messagesUpdatedCopy;
    }

    console.log(messagesMergedUpdated);
    console.log(
      'messagesMergedUpdated: ' + JSON.stringify(messagesMergedUpdated),
    );
    setMessagesMerged(messagesMergedUpdated);
  };

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchRoomDetails = async () => {
    try {
      const response = await axios.get(
        `${serverURL}/room/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`,
        {
          headers: { Authorization: `Bearer ${jwtTokenRef.current}` },
        },
      );
      const roomData = response.data;

      passwordRef.current = roomData.password;

      const messagesList = roomData.messages
        ? JSON.parse(roomData.messages)
        : [];

      var roomTranscript = '';
      for (let i = 0; i < messagesList.length; i++) {
        roomTranscript = roomTranscript + messagesList[i].transcript;
      }
      chatbotContextRef.current = roomTranscript;

      // make a copy of messagesRef
      var messagesRefCopy = JSON.parse(JSON.stringify(messagesRef.current));

      // Create a Map for faster lookup by transcript_id
      const messagesMap = new Map(
        messagesRefCopy.map((message) => [message.transcript_id, message]),
      );

      for (let i = 0; i < messagesList.length - 2; i++) {
        const matchedMessage = messagesMap.get(messagesList[i].transcript_id);
        if (matchedMessage) {
          messagesList[i].corrected_transcripts =
            matchedMessage.corrected_transcripts;
        }
      }

      var messagesListCopy = JSON.parse(JSON.stringify(messagesList));
      setMessages(messagesList);
      updateMessagesMerged(messagesListCopy || []);
      // check if we should scroll
      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        shouldScroll.current = atBottom;
      }
    } catch (error) {
      console.error('Error fetching room details:', error);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const roomId = pathname.split('/').pop();
    const password = params.get('password');
    roomIdRef.current = roomId;
    passwordRef.current = password;
    const intervalId = setInterval(
      () => fetchRoomDetails(roomId, password),
      1000,
    );

    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, [pathname]);

  useEffect(() => {
    const correctedMessagesContainer = messagesContainerRef.current;
    if (correctedMessagesContainer) {
      let lastScrollTop = correctedMessagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = correctedMessagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          shouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      if (correctedMessagesContainer) {
        correctedMessagesContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (correctedMessagesContainer) {
          correctedMessagesContainer.removeEventListener(
            'scroll',
            handleScroll,
          );
        }
      };
    }
  }, []);

  const correctedScrollToBottom = () => {
    setShouldShowScrollButton(false);
    messagesContainerRef.current?.scrollTo({
      top: Math.ceil(messagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (shouldScroll.current) {
      correctedScrollToBottom();
    }
  }, [messagesMerged]);

  const addLanguage = async (language, passwordRef) => {
    const roomId = roomIdRef.current; // Assuming roomIdRef is defined and holds the current room ID
    try {
      const response = await axios.post(
        `${serverURL}/room/${roomId}/add_language`,
        {
          language: language,
          password: passwordRef.current,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      if (response.status === 200) {
        console.log('Language added successfully:', response.data);
      } else {
        console.error('Failed to add language:', response.status);
      }
    } catch (error) {
      console.error('Error adding language:', error);
    }
  };

  const handleInputLanguageClick = (language, passwordRef, messagesRef) => {
    setLanguageDropdownVisible(false);

    addLanguage(language, passwordRef, messagesRef);
    if (language != inputLanguage.current) {
      let textsToTranslate = [];
      let messagesAlreadyTranslated = [];
      console.log('messages', messagesRef.current);
      for (let i = 0; i < messagesRef.current.length; i++) {
        let message = messagesRef.current[i];
        if (message['corrected_transcripts'][language] != undefined) {
          messagesAlreadyTranslated.push(i);
          continue;
        }
        textsToTranslate.push(message.transcript);
      }
      console.log('texts to translate: ' + textsToTranslate);
      translateTexts(textsToTranslate, language).then((translations) => {
        console.log('translations: ' + translations);
        let updatedMessages = [];
        for (let i = 0; i < messagesRef.current.length; i++) {
          let message = messagesRef.current[i];
          if (messagesAlreadyTranslated.includes(i)) {
            updatedMessages.push(message);
            continue;
          } else {
            if (message['corrected_transcripts'][language] == undefined) {
              message['corrected_transcripts'][language] =
                translations[i] + ' ';
            }
            updatedMessages.push(message);
          }
        }
        var updatedMessagesCopy = JSON.parse(JSON.stringify(updatedMessages));
        setMessages(updatedMessages);
        updateMessagesMerged(updatedMessagesCopy);
        localStorage.setItem('messages', JSON.stringify(messagesMerged));
      });
    }
    inputLanguage.current = language;
    setInputLanguageDisplay(language);
  };

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        inputLanguage.current &&
        inputLanguage.current !== 'Detect Language' &&
        inputLanguage.current !== 'Translate'
      ) {
        addLanguage(inputLanguage.current, passwordRef, messagesRef);
      }
    }, 5000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Removed dependencies to prevent creating new intervals on changes

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    let lastScrollTop = messagesContainer.scrollTop;

    const handleScroll = () => {
      const currentScrollTop = messagesContainer.scrollTop;
      if (currentScrollTop < lastScrollTop) {
        // User is scrolling up
        shouldScroll.current = false;
      }
      lastScrollTop = currentScrollTop;
    };

    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        linkAndQRCodeRef.current &&
        !linkAndQRCodeRef.current.contains(event.target)
      ) {
        setShowLinkQRCode(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className='flex bg-whitesmoke w-full h-full'>
      {showLinkQRCode && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/20'>
          <RoomLinkAndQRCode
            link={`https://interpretapp.ai/broadcast/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`}
            onOutsideClickFunc={() => setShowLinkQRCode(false)}
            divRef={linkAndQRCodeRef}
            isWeb={true}
          />
        </div>
      )}
      <div
        className='h-screen w-28 shadow-md flex flex-col gap-6 items-end pt-12 pr-8'
        style={{
          background:
            'linear-gradient(103deg, rgba(0, 126, 243, 0.10) 0.03%, rgba(121, 119, 209, 0.10) 99.97%), let(--white, #FFF)',
        }}
      >
        <img src='/logo.svg' alt='logo' className='w-8 h-8 mb-16' />
        <button
          onClick={() => {
            if (userInfoRef.current) navigate('/transcribe');
            else navigate('/signup');
          }}
          className='relative font-montserrat hover:opacity-80'
          onMouseOver={() => setShowBackTooltip(true)}
          onMouseOut={() => setShowBackTooltip(false)}
        >
          <img
            src='/broadcastback.svg'
            alt='broadcastback'
            className='w-8 h-8'
          />
          {showBackTooltip && (
            <div
              className={`absolute top-[5px] -right-14 text-white bg-updated-color-new-black rounded-md p-1 px-2 transition-all duration-300 ${showBackTooltip ? 'opacity-80' : 'opacity-0'}`}
            >
              Back
            </div>
          )}
        </button>
        <button
          className='relative font-montserrat hover:opacity-80 transition-all duration-300'
          onClick={() => setShowLinkQRCode(true)}
          onMouseOver={() => setShowShareTooltip(true)}
          onMouseOut={() => setShowShareTooltip(false)}
        >
          <img
            src='/broadcastshare.svg'
            alt='broadcastback'
            className='w-8 h-8'
          />
          <div
            className={`absolute top-[5px] -right-14 text-white bg-updated-color-new-black rounded-md p-1 px-2 transition-all duration-300 ${showShareTooltip ? 'opacity-80' : 'opacity-0'}`}
          >
            Share
          </div>
        </button>
      </div>
      <div
        className={`flex ${isChatbotOpen ? 'mr-[500px]' : ''} flex-col gap-6 justify-center w-full h-screen px-14`}
      >
        <div className='flex justify-between items-center relative'>
          <div className='font-montserrat w-full mr-6'>
            Room Created {formattedDate}
          </div>

          

       


          
          <div className='mr-4'>
            <Switch
              isOn={liveAudioEnabled}
              handleToggle={toggleLiveAudio}
              onColor='bg-dodgerblue-200'
              text='Live Audio'
            />
          </div>

          <div className="px-4">

          <div
    className='w-fit rounded-34xl h-fit flex py-1.5 px-3 border-[0.5px] border-solid border-updated-color-blue bg-white cursor-pointer'
    onClick={() => setFontSizeDropdownVisible(!fontSizeDropdownVisible)}
  >
    <div className='flex gap-[10px] cursor-pointer'>
        <b className='relative w-24'>Font Size: </b>
        
      </div>
    <div>{fontSize}</div>
    <img
          className='w-5 pl-2 relative h-5'
          alt=''
          src='/select-more-language.svg'
        />
  </div>
  </div>
  {fontSizeDropdownVisible && (
    <div className='absolute top-12 right-52 bg-white border border-solid border-gray-300 rounded-md shadow-lg z-10 font-poppins'>
      <div 
        className='px-4 py-2 hover:bg-slate-300 cursor-pointer rounded-md font-poppins'
        onClick={() => {
          setFontSize('Medium');
          setFontSizeDropdownVisible(false);
        }}
      >
        Medium (16px)
      </div>
      <div 
        className='px-4 py-2 hover:bg-slate-300 cursor-pointer rounded-md'
        onClick={() => {
          setFontSize('Large');
          setFontSizeDropdownVisible(false);
        }}
      >
        Large (20px)
      </div>
      <div 
        className='px-4 py-2 hover:bg-slate-300 cursor-pointer rounded-md'
        onClick={() => {
          setFontSize('Extra_Large');
          setFontSizeDropdownVisible(false);
        }}
      >
        Extra-Large (24px)
      </div>
    </div>
  )}

          
          <div
            ref={inputLanguageRef}
            className='w-fit rounded-34xl h-fit flex py-1.5 px-10 border-[0.5px] border-solid border-updated-color-blue cursor-pointer bg-white'
            onClick={() => {
              setLanguageDropdownVisible(!languageDropdownVisible);
              usingInputLanguagePopupRef.current =
                !usingInputLanguagePopupRef.current;
            }}
          >
            <div className='flex flex-col py-1.5 px-2.5'>
              <div className='flex   gap-[10px] cursor-pointer'>
                <b className='relative'>{inputLanguageDisplay}</b>
                <img
                  className='w-5 relative h-5'
                  alt=''
                  src='/select-more-language.svg'
                />
              </div>
            </div>
          </div>
          {languageDropdownVisible && (
            <div
              ref={inputLanguageDropdownRef}
              className='grid grid-cols-4 gap-8 px-10 bg-white border absolute top-12 right-0 border-solid border-gray-300 rounded-md shadow-lg z-10 max-h-96 overflow-y-auto w-full p-4'
            >
              <div className='col-span-4 h-fit flex items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='w-full p-1 border-none outline-none text-lg font-montserrat'
                  value={searchBarInput}
                  onChange={(e) => setSearchBarInput(e.target.value)}
                />
                <img
                  className='w-5 h-5 ml-2'
                  alt=''
                  src='/general--search.svg'
                />
              </div>
              {top100WorldLanguages
                .filter((language) =>
                  language
                    .toLowerCase()
                    .includes(searchBarInput?.toLowerCase() || ''),
                )
                .map((language) => (
                  <div
                    key={language}
                    className=' hover:bg-gray-200 cursor-pointer w-full h-fit'
                    onClick={() =>
                      handleInputLanguageClick(
                        language,
                        passwordRef,
                        messagesRef,
                      )
                    }
                  >
                    {language}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div
          className='flex relative flex-col w-full text-xs text-updated-color-new-black font-montserrat h-2/3 bg-white rounded-lg border-solid border-px border-updated-color-grey1'
          onTransitionEnd={() => {
            if (shouldScroll.current) {
              // check if at the bottom
              const messagesContainer = messagesContainerRef.current;
              if (messagesContainer) {
                const { scrollTop, clientHeight, scrollHeight } =
                  messagesContainer;
                const atBottom =
                  Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
                if (atBottom) {
                  shouldScroll.current = false;
                }
              }
            }
          }}
        >
          <div
  className={`flex flex-col text-left max-h-[80%] w-12/12 overflow-y-auto font-inter text-black font-light ${
    fontSize === 'Extra_Large' ? 'text-[24px]' : 
    fontSize === 'Large' ? 'text-[20px]' : 
    'text-[16px]'
  }`}
  ref={messagesContainerRef}
>
            {messagesMerged.map((message, index) => {
              const localTime = format(
                new Date(message.timestamp * 1000),
                'hh:mm:ss a',
              );
              return (
                <div key={index} className='flex m-4'>
                  <span className='mr-4 text-gray-600 w-24 whitespace-nowrap text-ellipsis  pr-10'>{localTime}</span>
                  {message.speaker && (
                    <span className='mr-4 font-bold'>{message.speaker}: </span>
                  )}
                  <div>
                    {inputLanguage.current === 'Translate' ||
                    inputLanguage.current === 'Detect Language'
                      ? message.transcript
                      : message.corrected_transcripts[inputLanguage.current]}
                    {message.unconfirmed_corrected_transcripts &&
                    inputLanguage.current in
                      message.unconfirmed_corrected_transcripts &&
                    message.unconfirmed_corrected_transcripts[
                      inputLanguage.current
                    ] ? (
                      <span style={{ color: 'grey' }}>
                        {
                          message.unconfirmed_corrected_transcripts[
                            inputLanguage.current
                          ]
                        }
                      </span>
                    ) : message.unconfirmed_transcript ? (
                      <span style={{ color: 'grey' }}>
                        {message.unconfirmed_transcript}
                      </span>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>


          <div className='flex items-center absolute z-10 left-4 bottom-1 '>


          <button 
    onClick={toggleFullScreen}
    className=' hover:bg-gray-100 '
  >
    <img
      src={isFullScreen ? '/exit.svg' : '/enter.svg'}
      alt='Toggle fullscreen'
      className='w-8 h-8 hover:w-9 hover:h-9 transition-all duration-300 border-solid rounded-md border-gray-500'
    />
  </button>
          </div>
          <div
            className={`w-fit absolute z-10 bottom-16 mx-auto left-0 right-0 cursor-pointer`}
          >
            {shouldShowScrollButton && (
              <img
                className='w-full h-full overflow-hidden'
                loading='lazy'
                alt=''
                src='/arrow--arrow-down-6-circle.svg'
                onClick={() => {
                  correctedScrollToBottom();
                }}
              />
            )}
          </div>
          <div className='absolute bg-white bottom-0 w-full pt-2 pb-2 rounded-b-lg'>
            <div className='flex justify-center mx-auto items-center w-11/12 gap-2'>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
              <div className='flex items-center justify-center'>
                <DotLottieReact
                  src='https://lottie.host/a425c32d-9541-45f1-95e0-2f3a44e31cb7/GWtgIB7950.json'
                  background='transparent'
                  speed='1'
                  loop
                  dotLottieRefCallback={(ref) => setLottieRef(ref)}
                  className='w-48'
                />
              </div>
              <hr className='border-dashed w-full border h-px border-b-0 border-x-0' />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: isChatbotOpen ? 'block' : 'none' }}>
        <ChatbotWidget
          isChatbotOpenRef={isChatbotOpenRef}
          onClose={() => setIsChatbotOpen(false)}
          contextRef={chatbotContextRef}
          roomId={roomIdRef.current}
          roomPassword={passwordRef.current}
        />
      </div>
      {!isChatbotOpen && (
        <img
          className='cursor-pointer fixed bottom-5 right-5'
          src='/chai2.svg'
          alt='Chatbot'
          onClick={() => setIsChatbotOpen(true)}
        />
      )}
    </div>
  );
};

export default BroadcastRoom;
