import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { serverURL, updateAccountInfo } from '../../httpContext';
import { GoogleAuthProvider, signInWithPopup, getAuth, OAuthProvider } from 'firebase/auth';

const InputField = ({
  label,
  type,
  value,
  onChange,
  onKeyPress,
  showPassword,
  togglePassword,
}) => (
  <div className='flex flex-col gap-2'>
    <label className='font-medium'>{label}</label>
    <div className='relative'>
      <input
        type={type === 'password' && showPassword ? 'text' : type}
        className='w-full rounded-md border-solid border border-gray-300 py-3 pl-1 pr-0 text-xl font-montserrat font-medium'
        value={value}
        onChange={onChange}
        onKeyDown={onKeyPress}
      />
      {type === 'password' && (
        <button
          type='button'
          className='absolute right-0 top-3'
          onClick={togglePassword}
        >
          {showPassword ? (
            <EyeInvisibleOutlined className='text-2xl' />
          ) : (
            <EyeOutlined className='text-2xl' />
          )}
        </button>
      )}
    </div>
  </div>
);

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const jwtTokenRef = useRef(null);
  const userInfoRef = useRef(null);

  const auth = getAuth();

  const googleProvider = new GoogleAuthProvider();
  googleProvider.setCustomParameters({ prompt: 'select_account' });

  const appleProvider = new OAuthProvider('apple.com');
  appleProvider.addScope('email');

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${serverURL}/login`, {
        email,
        password,
      });
      if (response.status === 200) {
        jwtTokenRef.current = response.data.access_token;
        localStorage.setItem('jwtToken', jwtTokenRef.current);
        await updateAccountInfo(jwtTokenRef, userInfoRef);
        navigate('/transcribe');
      } else {
        setError(response.data.detail || 'Invalid email or password');
      }
    } catch (error) {
      setError(error.response?.data?.detail || 'Internal server error');
    }
  };

  const handleOauthSignup = async (provider_type) => {
    var provider = googleProvider;
    var oauth_api = '/login-with-google';
    if (provider_type === "apple") {
      provider = appleProvider;
      oauth_api = '/login-with-google';
    } else if (provider_type === "google") {
      provider = googleProvider;
      oauth_api = '/login-with-google';
    }

    try {
      const result = await signInWithPopup(auth, provider);
      const token = await result.user.getIdToken();
      var source = localStorage.getItem('signup_source'); // Ensure source is an empty string if not found
      if (source === 'null' || source === null) {
        source = 'web';
      } else {
        source = source + '_web';
      }
      const response = await axios.post(
        `${serverURL}/login-with-google${source !== 'null' ? `?source=${encodeURIComponent(source)}` : ''}`,
        {
          token,
        },
      );

      if (window.rdt) {
        const rdt = window.rdt;
        rdt('track', 'Signup', {
          email: email, // Optionally pass the user's email if available
        });
      }

      jwtTokenRef.current = response.data.access_token;
      localStorage.setItem('jwtToken', jwtTokenRef.current);
      await updateAccountInfo(jwtTokenRef, userInfoRef);
      navigate('/transcribe');
    } catch (error) {
      setError('Authentication failed. Please try again.');
    }
  };

  return (
    <div className='relative max-w-[1512px] w-full mx-auto flex font-montserrat'>
      <div
        className='w-fit h-fit cursor-pointer flex items-center gap-[5px] absolute top-10 left-20'
        onClick={() => {
          navigate('/');
        }}
      >
        <img
          className='h-12 w-12'
          alt=''
          src='/1024px-x-1024px-round-w-text1.svg'
        />
        <div className='tracking-[-0.02em] text-updated-color-blue text-2xl font-medium'>
          interpret
        </div>
      </div>
      <div className='flex flex-col h-full w-full pr-20 gap-4 py-28 pl-56'>
        <h1 className='text-3xl font-semibold'>Login</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
          className='space-y-8'
        >
          <InputField
            label='Email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputField
            label='Password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            showPassword={showPassword}
            togglePassword={() => setShowPassword(!showPassword)}
          />
          {error && <p className='text-red-500 text-sm'>{error}</p>}
          <div className='flex justify-between items-center'>
            <label className='flex items-center font-medium hover:text-zinc-600'>
              <input type='checkbox' className='mr-2  ' />
              Keep me logged in
            </label>
            <button
              type='button'
              onClick={() => navigate('/forgot-password')}
              className='text-lg font-montserrat font-medium hover:text-gray-500 transition-all duration-300'
            >
              Forgot password?
            </button>
          </div>
          <button
            type='submit'
            className='w-full bg-blue-500 hover:bg-blue-400 text-white py-2 rounded-md text-2xl font-montserrat font-medium transition-all duration-300'
          >
            Log In
          </button>
        </form>
        <p className='mt-4 text-lg'>
          Don't have an account?{' '}
          <span
            onClick={() => navigate('/signup')}
            className='text-blue-500 underline font-montserrat font-medium text-lg cursor-pointer'
          >
            Create an Account
          </span>
        </p>
        <div className='w-full flex flex-row items-center justify-center'>
          <div className='w-full flex flex-row items-center justify-center gap-1'>
            <div className='w-full h-[1px] bg-updated-color-grey2' />
            <div className='flex items-center justify-center'>
              <span className='text-updated-color-grey2 font-medium text-lg'>
                OR
              </span>
            </div>
            <div className='w-full h-[1px] bg-updated-color-grey2' />
          </div>
        </div>
        <button
          onClick={() => handleOauthSignup('google')}
          className='w-full mt-4 flex relative items-center justify-center gap-2 py-3 rounded-[10px] font-montserrat font-medium text-[20px] bg-white text-black border-solid border-[#C1D5F6] hover:bg-slate-300 hover:text-neutral-600 transition-all duration-300 '
        >
          <img
            src='/google@2x.png'
            alt='Google'
            className='w-8 h-8 absolute left-2 top-[0.7rem]'
          />
          Sign In with Google
        </button>


        <button
          onClick={() => handleOauthSignup('apple')}
          className='w-full mt-2 flex relative items-center justify-center gap-2 py-3 rounded-[10px] font-montserrat font-medium text-[20px] bg-white text-black border-solid border-[#C1D5F6] hover:bg-slate-300 hover:text-neutral-600 transition-all duration-300 '
        >
          <img
            src='/apple.svg'
            alt='Google'
            className='w-6 h-7 absolute left-3 top-[0.7rem]'
          />
          Sign In with Apple
        </button>

        {/* <button
          className='w-full mt-2 flex relative items-center justify-center gap-2 py-3 rounded-[10px] font-montserrat font-medium text-[20px] bg-white text-black border-solid border-[#C1D5F6] hover:bg-slate-300 hover:text-neutral-600 transition-all duration-300 '
        >
          <img
            src='/microsoft.svg'
            alt='Google'
            className='w-7 h-7 absolute left-3 top-[0.7rem]'
          />
          Sign Up with Microsoft
        </button> */}
      </div>
      <div className='w-full bg-gradient-to-b from-[#7278d4]  via-[#597ade] to-blue-500  h-auto'></div>
    </div>
  );
};

export default Login;
